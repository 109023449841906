<template>
  <div class="global-box">
    <!-- 导航栏 -->
    <div class="bird-nav">
      <!-- 时间选择器 -->
      <a-config-provider :locale="zhCN">
        <a-range-picker
          style="width: 300px"
          format="YYYY-MM-DD"
          :placeholder="['起始时间', '终止时间']"
          :value="createValue"
          @change="onChange"
          @ok=";"
        />
      </a-config-provider>

      <!-- 区域选择 -->
      <a-select
        v-model:value="region"
        style="width: 100px; margin-left: 5px; margin-right: 5px"
        placeholder="请选择区块"
        @change="regionChange"
      >
        <a-select-option value="0">全区</a-select-option>
        <a-select-option value="1">区块1</a-select-option>
        <a-select-option value="2">区块2</a-select-option>
        <a-select-option value="3">区块3</a-select-option>
        <a-select-option value="4">区块4</a-select-option>
        <a-select-option value="5">区块5</a-select-option>
        <a-select-option value="6">区块6</a-select-option>
        <a-select-option value="7">区块7</a-select-option>
        <a-select-option value="8">区块8</a-select-option>
      </a-select>

      <!-- 查询图片 -->
      <a-button type="primary" @click="getPicturedata">
        <template #icon>
          <SearchOutlined />
        </template>
        <span>查询图片</span>
      </a-button>

      <!-- 查询视频 -->
      <a-button type="primary" @click="getVideoData" style="margin-left: 5px">
        <template #icon>
          <SearchOutlined />
        </template>
        <span>查询视频</span>
      </a-button>
    </div>

    <!-- 表格框 -->
    <div class="table-box">
      <div style="margin: 5px 0">
        <!--展现图片表格，隐藏视频表格-->
        <a-button
          type="primary"
          shape="circle"
          size="large"
          @click="showPicTable"
          style="margin-right: 20px"
        >
          <template #icon>
            <PictureOutlined />
          </template>
        </a-button>
        <!--展现视频表格，隐藏图片表格-->
        <a-button
          type="primary"
          shape="circle"
          size="large"
          @click="showVideoTable"
        >
          <template #icon>
            <PlaySquareOutlined />
          </template>
        </a-button>
      </div>
      <!--图片表格-->
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="pagination"
        @change="changePicTableData"
        class="transparent-table"
        :customRow="rowClick"
        rowKey="id"
        v-show="showWhich"
        :scroll="{ y: 500 }"
      >
        <template #regionId="{ text }">
          <span v-if="text == 0">区外</span>
          <span v-if="text == 1">区块1</span>
          <span v-if="text == 2">区块2</span>
          <span v-if="text == 3">区块3</span>
          <span v-if="text == 4">区块4</span>
          <span v-if="text == 5">区块5</span>
          <span v-if="text == 6">区块6</span>
          <span v-if="text == 7">区块7</span>
          <span v-if="text == 8">区块8</span>
        </template>
        <template #type="{ text }">
          <span v-if="text == 0">原始图片</span>
          <span v-if="text == 3">人工核查</span>
        </template>
        <template #action="{ text }">
          <img
            :src="text.photoUrl"
            alt="缩略图"
            width="60"
            @dblclick="showPhoto(text)"
            style="cursor: pointer"
          />
        </template>
      </a-table>
      <!--视频表格-->
      <a-table
        :columns="columnsVideo"
        :data-source="videoData"
        v-show="!showWhich"
        class="transparent-table"
        :loading="loadingVideo"
        :pagination="paginationVideo"
        @change="changeVideoTableData"
        :customRow="rowClickVideo"
        rowKey="id"
      >
        <template #action="{ text }">
          <img
            :src="text.videoCoverUrl"
            alt="视频缩略图"
            width="40"
            @dblclick="showVideo(text)"
            style="cursor: pointer"
          />
        </template>
      </a-table>
    </div>

    <!-- 地图图层 -->
    <div class="map-box">
      <div id="bird-map"></div>
    </div>
  </div>
  <!-- 图层展示 -->
  <div class="pic-box" ref="pic_ref" style="display: none">
    <div class="delete-box">
      <a-button type="danger" @click="deletePic" shape="circle" class="a-button"
        >×</a-button
      >
    </div>
    <!--切换图片模式-->
    <div class="change-box">
      <a-button type="primary" @click="changeLevel">切换图片模式</a-button>
    </div>
    <!--图片180°翻转-->
    <div class="is-show-box">
      <a-button type="primary" @click="changeImgPosition">图片翻转</a-button>
    </div>
    <div class="charm-box" :disabled="pythonImgInfo.type === 3">
      <a-button type="primary" @click="openUploadCharm"
        >上传至魅力东疆</a-button
      >
    </div>
    <!--定点任务派送-->
    <div class="task-box" v-show="role === 0">
      <a-button type="primary" @click="createTask">
        <template #icon><EnvironmentOutlined /></template>
        任务派送
      </a-button>
    </div>
    <!--拍摄时间展示-->
    <div class="tag-box">拍摄时间：{{ pythonImgInfo.photoTime }}</div>
    <div class="tag-gps-box">坐标：{{ pythonImgInfo.gps }}</div>
    <!--前进按钮-->
    <div class="go-button-box" @click="nextFun" v-show="isButtonGoShow">
      <span>
        <RightCircleOutlined style="color: #fff; font-size: 70px" />
      </span>
    </div>
    <!--后退按钮-->
    <div class="back-button-box" @click="backFun" v-show="isButtonBackShow">
      <span> <LeftCircleOutlined style="color: #fff; font-size: 70px" /> </span>
    </div>
    <!--图片本身-->
    <div id="image-box" ref="imgRef"></div>
  </div>
  <!--视频预览-->
  <a-modal
    v-model:visible="videoVisible"
    title="视频播放"
    :footer="null"
    width="800"
    :centered="true"
  >
    <video :src="videoUrl" muted controls width="800" height="500"></video>
  </a-modal>
  <!--上传至魅力东疆的模态框-->
  <a-modal
    v-model:visible="visibleCharm"
    title="上传至魅力东疆"
    @ok="uploadCharm"
  >
    <p>输入文字描述后点击确定即可上传至魅力东疆板块，点击取消返回</p>
    <a-textarea
      v-model:value="text"
      placeholder="请输入与该图片相关的文字描述"
      allow-clear
      size="large"
      :rows="8"
    />
  </a-modal>
  <!--定点任务的模态框-->
  <a-modal v-model:visible="visibleTask" title="定点任务派送" @ok="uploadTask">
    <div style="margin-bottom: 15px">
      <label for="task-name">任务名称：</label>
      <a-input
        id="task-name"
        disabled
        value="清理此处垃圾"
        style="width: 40%"
      ></a-input>
    </div>
    <div style="margin-bottom: 15px">
      <label for="task-message">任务描述：</label>
      <a-textarea
        id="task-message"
        v-model:value="taskMessage"
        placeholder="任务描述，可以为空"
        allow-clear
        class="task-text-area"
      />
    </div>
    <div style="margin-bottom: 15px">
      <label for="task-time">起始时间：</label>
      <a-config-provider :locale="zhCN" id="task-time" style="width: 300px">
        <a-range-picker
          style="width: 300px"
          v-model:value="taskTimeValues"
          :placeholder="['起始日期', '终止日期']"
          :value="taskTimeValues"
          @change="taskTime.startTime = taskTimeValues[0]; taskTime.endTime = taskTimeValues[1];"
          @ok=";"
        />
      </a-config-provider>
    </div>
    <div>
      <label for="task-cleaner-select">清理人员：</label>
      <a-select
        v-model:value="cleaner"
        style="width: 180px"
        id="task-cleaner-select"
        placeholder="选择垃圾清理人员"
      >
        <a-select-option
          v-for="(item, index) in cleanerList"
          :value="item.id"
          :key="index"
        >
          {{ item.userName }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>
<script>
import { getAnyDate } from "@/tools/datefmt";
import { SearchOutlined } from "@ant-design/icons-vue";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import { message } from "ant-design-vue";
import AILabel from "ailabel";
import "moment/dist/locale/zh-cn";
import { defineComponent } from "vue";
import {
  PictureOutlined,
  PlaySquareOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons-vue";
moment.locale("zn");
const columns = [
  {
    title: "上传者", //名称的
    dataIndex: "operator.userName", //与下面data里面的数据统一起来，显示的
    key: "operator.userName", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    align: "center",
    width: "30%",
  },
  {
    title: "区块", //名称的
    dataIndex: "regionId", //与下面data里面的数据统一起来，显示的
    key: "regionId", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    align: "center",
    slots: { customRender: "regionId" },
    width: "14%",
  },
  {
    title: "拍摄时间",
    dataIndex: "photoTime",
    key: "photoTime",
    align: "center",
    width: "38%",
  },
  {
    title: "缩略图",
    dataIndex: "typeAndUrl",
    key: "typeAndUrl",
    align: "center",
    slots: { customRender: "action" },
    width: "20%",
  },
];
const columnsVideo = [
  {
    title: "视频名称", //名称
    dataIndex: "fileName", //与下面data里面的数据统一起来，显示的
    key: "fileName", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    align: "center",
    width: "25%",
  },
  {
    title: "上传者", //名称
    dataIndex: "operator.userName", //与下面data里面的数据统一起来，显示的
    key: "operator.userName", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    align: "center",
    width: "30%",
  },
  {
    title: "拍摄时间",
    dataIndex: "takeTime",
    key: "takeTime",
    align: "center",
    width: "25%",
  },
  {
    title: "视频封面",
    dataIndex: "videoUrlModel",
    key: "videoUrlModel",
    align: "center",
    slots: { customRender: "action" },
    width: "20%",
  },
];
export default defineComponent({
  components: {
    SearchOutlined,
    PictureOutlined,
    PlaySquareOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,
    EnvironmentOutlined,
  },
  data() {
    return {
      //默认时间日期以及中文显示
      createValue: [getAnyDate(-7), getAnyDate(0)],
      enUS: enUS,
      zhCN: zhCN,
      //搜索用的信息
      startTime: getAnyDate(-7), //图片拍摄起始时间
      endTime: getAnyDate(0), //图片拍摄终止时间
      region: "0", //图片拍摄区域
      type: "4", //图片类型
      //图片列表所需信息
      columns: columns, //列表本身
      data: [], //列表需要的数据
      imgaeIndex: 0, //图片列表的索引
      loading: false, //列表加载缓冲提示
      pagination: {
        pageSize: 50, //一页是50条数据
        total: 5, //总页数
        current: 1, //当前是第几页
      },
      //视频列表所需信息
      columnsVideo: columnsVideo, //视频列表本身
      videoData: [], //视频列表数据
      loadingVideo: false, //列表加载缓冲提示
      paginationVideo: {
        pageSize: 10, //一页是10条数据
        total: 5, //总页数
        current: 1, //当前是第几页
      },
      videoUrl: "", //选择的预览视频地址
      //展示哪一个表格
      showWhich: true,
      //视频预览展示框是否展示
      videoVisible: false,
      lastIndexVideo: 0,
      //地图本身
      map: null,
      //图层的点的渲染
      markerPoint: null, //点的坐标
      label: null, //点的标注
      selectedRow: null, //选择的行
      lastIndex: 0, //上一个选择的序号
      //一次性渲染的点的数组
      pointList: [],
      //一次性渲染的折线数组
      polylineList: [],

      //python效果
      //用于保存当前双击选中的图片信息以及它的对应识别图片
      pythonImgInfo: {},
      isPythonUrl: 0,
      isFeaturesShow: 1,
      featureButtonTitle: "隐藏人工标注框",

      //寻找附近最近的点
      totalArray: [],
      isButtonGoShow: false,
      isButtonBackShow: false,
      nowPositionInArray: 0, //现在所显示的图片在数组中的位置
      nowIndex: 0, //被双击打开的图片的位置

      //上传至魅力东疆的模态框是否打开
      visibleCharm: false,
      text: "", //文本描述

      //判断图片的位置是否正常
      isImgPositionRight: true,

      //页面上方的原始图层
      gFetureStyle: {}, //固定样式
      gMap: {}, //原始图层
      gImageLayer: {}, //图片图层

      //定点任务派送
      visibleTask: false,
      //任务描述
      taskMessage: "",
      //任务时间
      taskTimeValues: [getAnyDate(0), getAnyDate(3)],
      taskTime: {
        startTime: getAnyDate(0),
        endTime: getAnyDate(3),
      },
      //清理人员列表
      cleanerList: [],
      //被派遣者
      cleaner: null,
      //登录用户角色等级
      role: 0,
    };
  },
  methods: {
    //地图初始化
    init() {
      // eslint-disable-next-line no-undef
      let point = new BMap.Point(117.816405, 39.019237);
      // eslint-disable-next-line no-undef
      this.map = new BMap.Map("bird-map", {
        coordsType: 5,
        enableBizAuthLogo: false,
        // eslint-disable-next-line no-undef
        mapType: BMAP_SATELLITE_MAP,
      });
      this.map.centerAndZoom(point, 13);
      this.map.enableScrollWheelZoom(true);
      this.map.disableBizAuthLogo(); //关闭
      // eslint-disable-next-line no-undef
      this.map.addControl(new BMap.ScaleControl());
      // eslint-disable-next-line no-undef
      this.map.addControl(new BMap.OverviewMapControl());
      // eslint-disable-next-line no-undef
      this.map.addControl(
        // eslint-disable-next-line no-undef
        new BMap.MapTypeControl({
          // eslint-disable-next-line no-undef
          mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP, BMAP_SATELLITE_MAP],
        })
      );

      //加区域
      // 海滩1
      // eslint-disable-next-line no-undef
      let beach_1_1 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let beach_1_2 = new BMap.Point(117.81378196661903, 39.06251340098135);
      // eslint-disable-next-line no-undef
      let beach_1_3 = new BMap.Point(117.77158910487401, 39.07714589126867);
      // eslint-disable-next-line no-undef
      let beach_1_4 = new BMap.Point(117.77395669519076, 39.081229481876189);
      // eslint-disable-next-line no-undef
      let beach_1_5 = new BMap.Point(117.81900068882274, 39.065698500108499);

      // 海漂1
      // eslint-disable-next-line no-undef
      let float_1_1 = new BMap.Point(117.82371855575747, 39.06808342595199);
      // eslint-disable-next-line no-undef
      let float_1_2 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let float_1_3 = new BMap.Point(117.77395669519076, 39.081229481876189);
      // eslint-disable-next-line no-undef
      let float_1_4 = new BMap.Point(117.77586076006807, 39.084517834757367);
      // eslint-disable-next-line no-undef
      let float_1_5 = new BMap.Point(117.82371855575747, 39.06808342595199);

      // 海滩2
      // eslint-disable-next-line no-undef
      let beach_2_1 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let beach_2_2 = new BMap.Point(117.82103290947602, 39.03749143985976);
      // eslint-disable-next-line no-undef
      let beach_2_3 = new BMap.Point(117.81378196661903, 39.06251340098135);
      // eslint-disable-next-line no-undef
      let beach_2_4 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let beach_2_5 = new BMap.Point(117.82681672118544, 39.03872394147336);

      // 海漂2
      // eslint-disable-next-line no-undef
      let float_2_1 = new BMap.Point(117.83169246071212, 39.03974933415886);
      // eslint-disable-next-line no-undef
      let float_2_2 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let float_2_3 = new BMap.Point(117.81900068882274, 39.065698500108499);
      // eslint-disable-next-line no-undef
      let float_2_4 = new BMap.Point(117.82371855575747, 39.06808342595199);
      // eslint-disable-next-line no-undef
      let float_2_5 = new BMap.Point(117.83169246071212, 39.03974933415886);

      // 海滩3
      // eslint-disable-next-line no-undef
      let beach_3_1 = new BMap.Point(117.83698288200677, 39.020033970995388);
      // eslint-disable-next-line no-undef
      let beach_3_2 = new BMap.Point(117.82646624113764, 39.01865580692072);
      // eslint-disable-next-line no-undef
      let beach_3_3 = new BMap.Point(117.82103290947602, 39.03749143985976);
      // eslint-disable-next-line no-undef
      let beach_3_4 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let beach_3_5 = new BMap.Point(117.83152543389177, 39.02240307114145);
      // eslint-disable-next-line no-undef
      let beach_3_6 = new BMap.Point(117.83667164466046, 39.02163855214231);
      // eslint-disable-next-line no-undef
      let beach_3_7 = new BMap.Point(117.83698288200677, 39.020033970995388);

      // 海漂3
      // eslint-disable-next-line no-undef
      let float_3_1 = new BMap.Point(117.82681672118544, 39.03872394147336);
      // eslint-disable-next-line no-undef
      let float_3_2 = new BMap.Point(117.83169246071212, 39.03974933415886);
      // eslint-disable-next-line no-undef
      let float_3_3 = new BMap.Point(117.83667164466046, 39.02163855214231);
      // eslint-disable-next-line no-undef
      let float_3_4 = new BMap.Point(117.83152543389177, 39.02240307114145);
      // eslint-disable-next-line no-undef
      let float_3_5 = new BMap.Point(117.82681672118544, 39.03872394147336);

      // 海滩4
      // eslint-disable-next-line no-undef
      let beach_4_1 = new BMap.Point(117.82646624113764, 39.01865580692072);
      // eslint-disable-next-line no-undef
      let beach_4_2 = new BMap.Point(117.83698288200677, 39.020033970995388);
      // eslint-disable-next-line no-undef
      let beach_4_3 = new BMap.Point(117.83761835472298, 39.018365247317728);
      // eslint-disable-next-line no-undef
      let beach_4_4 = new BMap.Point(117.83620806862766, 39.0173200452412);
      // eslint-disable-next-line no-undef
      let beach_4_5 = new BMap.Point(117.83546980836316, 39.016567735300217);
      // eslint-disable-next-line no-undef
      let beach_4_6 = new BMap.Point(117.83550099015955, 39.01542243858008);
      // eslint-disable-next-line no-undef
      let beach_4_7 = new BMap.Point(117.83866002531846, 39.00224131168685);
      // eslint-disable-next-line no-undef
      let beach_4_8 = new BMap.Point(117.84308448338861, 39.003029840997509);
      // eslint-disable-next-line no-undef
      let beach_4_9 = new BMap.Point(117.84382896231145, 38.999922513122147);
      // eslint-disable-next-line no-undef
      let beach_4_10 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let beach_4_11 = new BMap.Point(117.83243814134167, 38.997898345009968);
      // eslint-disable-next-line no-undef
      let beach_4_12 = new BMap.Point(117.82646624113764, 39.01865580692072);

      // 海漂4
      // eslint-disable-next-line no-undef
      let float_4_1 = new BMap.Point(117.84308448338861, 39.003029840997509);
      // eslint-disable-next-line no-undef
      let float_4_2 = new BMap.Point(117.83866002531846, 39.00224131168685);
      // eslint-disable-next-line no-undef
      let float_4_3 = new BMap.Point(117.83550099015955, 39.01542243858008);
      // eslint-disable-next-line no-undef
      let float_4_4 = new BMap.Point(117.83546980836316, 39.016567735300217);
      // eslint-disable-next-line no-undef
      let float_4_5 = new BMap.Point(117.83620806862766, 39.0173200452412);
      // eslint-disable-next-line no-undef
      let float_4_6 = new BMap.Point(117.83761835472298, 39.018365247317728);
      // eslint-disable-next-line no-undef
      let float_4_7 = new BMap.Point(117.8384719607511, 39.018347543211458);
      // eslint-disable-next-line no-undef
      let float_4_8 = new BMap.Point(117.83978767656461, 39.01779603050196);
      // eslint-disable-next-line no-undef
      let float_4_9 = new BMap.Point(117.84170582553414, 39.016738947479208);
      // eslint-disable-next-line no-undef
      let float_4_10 = new BMap.Point(117.84311732391645, 39.015476103384099);
      // eslint-disable-next-line no-undef
      let float_4_11 = new BMap.Point(117.84441913321513, 39.01367793100998);
      // eslint-disable-next-line no-undef
      let float_4_12 = new BMap.Point(117.845713824849, 39.011305537871617);
      // eslint-disable-next-line no-undef
      let float_4_13 = new BMap.Point(117.84625448364749, 39.00861173921601);
      // eslint-disable-next-line no-undef
      let float_4_14 = new BMap.Point(117.8455191204376, 39.006067145913);
      // eslint-disable-next-line no-undef
      let float_4_15 = new BMap.Point(117.8447794885481, 39.004790072048127);
      // eslint-disable-next-line no-undef
      let float_4_16 = new BMap.Point(117.84381388550297, 39.00369347260906);
      // eslint-disable-next-line no-undef
      let float_4_17 = new BMap.Point(117.84308448338861, 39.003029840997509);

      //加区域
      // eslint-disable-next-line no-undef
      let beach_1 = new BMap.Polygon(
        [beach_1_1, beach_1_2, beach_1_3, beach_1_4, beach_1_5],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_1);

      // eslint-disable-next-line no-undef
      let beach_2 = new BMap.Polygon(
        [beach_2_1, beach_2_2, beach_2_3, beach_2_4, beach_2_5],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_2);

      // eslint-disable-next-line no-undef
      let beach_3 = new BMap.Polygon(
        [
          beach_3_1,
          beach_3_2,
          beach_3_3,
          beach_3_4,
          beach_3_5,
          beach_3_6,
          beach_3_7,
        ],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_3);

      // eslint-disable-next-line no-undef
      let beach_4 = new BMap.Polygon(
        [
          beach_4_1,
          beach_4_2,
          beach_4_3,
          beach_4_4,
          beach_4_5,
          beach_4_6,
          beach_4_7,
          beach_4_8,
          beach_4_9,
          beach_4_10,
          beach_4_11,
          beach_4_12,
        ],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_4);

      // eslint-disable-next-line no-undef
      let float_1 = new BMap.Polygon(
        [float_1_1, float_1_2, float_1_3, float_1_4, float_1_5],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_1);

      // eslint-disable-next-line no-undef
      let float_2 = new BMap.Polygon(
        [float_2_1, float_2_2, float_2_3, float_2_4, float_2_5],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_2);

      // eslint-disable-next-line no-undef
      let float_3 = new BMap.Polygon(
        [float_3_1, float_3_2, float_3_3, float_3_4, float_3_5],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_3);

      // eslint-disable-next-line no-undef
      let float_4 = new BMap.Polygon(
        [
          float_4_1,
          float_4_2,
          float_4_3,
          float_4_4,
          float_4_5,
          float_4_6,
          float_4_7,
          float_4_8,
          float_4_9,
          float_4_10,
          float_4_11,
          float_4_12,
          float_4_13,
          float_4_14,
          float_4_15,
          float_4_16,
          float_4_17,
        ],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_4);

      // eslint-disable-next-line no-undef
      let beach_5_1 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let beach_5_2 = new BMap.Point(117.836325984016, 38.98439342240584);
      // eslint-disable-next-line no-undef
      let beach_5_3 = new BMap.Point(117.83243814134167, 38.997898345009968);
      // eslint-disable-next-line no-undef
      let beach_5_4 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let beach_5_5 = new BMap.Point(117.84257549925502, 38.98382773693862);

      // eslint-disable-next-line no-undef
      let float_5_1 = new BMap.Point(117.84740972723692, 38.98499128135028);
      // eslint-disable-next-line no-undef
      let float_5_2 = new BMap.Point(117.84314405700256, 38.98152934313679);
      // eslint-disable-next-line no-undef
      let float_5_3 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let float_5_4 = new BMap.Point(117.83891614345646, 38.99892236426643);
      // eslint-disable-next-line no-undef
      let float_5_5 = new BMap.Point(117.84382896231145, 38.999922513122147);
      // eslint-disable-next-line no-undef
      let float_5_6 = new BMap.Point(117.84740972723692, 38.98499128135028);

      // eslint-disable-next-line no-undef
      let beach_6_1 = new BMap.Point(117.836325984016, 38.98439342240584);
      // eslint-disable-next-line no-undef
      let beach_6_2 = new BMap.Point(117.84257549925502, 38.98382773693862);
      // eslint-disable-next-line no-undef
      let beach_6_3 = new BMap.Point(117.84314405700256, 38.98152934313679);
      // eslint-disable-next-line no-undef
      let beach_6_4 = new BMap.Point(117.84068725569577, 38.97952486730796);
      // eslint-disable-next-line no-undef
      let beach_6_5 = new BMap.Point(117.82357454979995, 38.981675555739048);
      // eslint-disable-next-line no-undef
      let beach_6_6 = new BMap.Point(117.82181823709257, 38.98034784820357);
      // eslint-disable-next-line no-undef
      let beach_6_7 = new BMap.Point(117.80474113454149, 38.98985402441064);
      // eslint-disable-next-line no-undef
      let beach_6_8 = new BMap.Point(117.80144794141202, 38.99353310977236);
      // eslint-disable-next-line no-undef
      let beach_6_9 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let beach_6_10 = new BMap.Point(117.808126863064, 38.995339566936397);
      // eslint-disable-next-line no-undef
      let beach_6_11 = new BMap.Point(117.80901301361809, 38.99341457990975);
      // eslint-disable-next-line no-undef
      let beach_6_12 = new BMap.Point(117.81424363993087, 38.990099488818298);
      // eslint-disable-next-line no-undef
      let beach_6_13 = new BMap.Point(117.81975228153212, 38.987433928942177);
      // eslint-disable-next-line no-undef
      let beach_6_14 = new BMap.Point(117.82418803200905, 38.98611374560856);
      // eslint-disable-next-line no-undef
      let beach_6_15 = new BMap.Point(117.836325984016, 38.98439342240584);

      // eslint-disable-next-line no-undef
      let float_6_1 = new BMap.Point(117.7971991753089, 38.994114976252017);
      // eslint-disable-next-line no-undef
      let float_6_2 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let float_6_3 = new BMap.Point(117.80144794141202, 38.99353310977236);
      // eslint-disable-next-line no-undef
      let float_6_4 = new BMap.Point(117.80474113454149, 38.98985402441064);
      // eslint-disable-next-line no-undef
      let float_6_5 = new BMap.Point(117.82181823709257, 38.98034784820357);
      // eslint-disable-next-line no-undef
      let float_6_6 = new BMap.Point(117.82357454979995, 38.981675555739048);
      // eslint-disable-next-line no-undef
      let float_6_7 = new BMap.Point(117.84068725569577, 38.97952486730796);
      // eslint-disable-next-line no-undef
      let float_6_8 = new BMap.Point(117.83781149462408, 38.9771697476575);
      // eslint-disable-next-line no-undef
      let float_6_9 = new BMap.Point(117.8252979180732, 38.97881484370927);
      // eslint-disable-next-line no-undef
      let float_6_10 = new BMap.Point(117.82373942321326, 38.97758556544104);
      // eslint-disable-next-line no-undef
      let float_6_11 = new BMap.Point(117.82047307889886, 38.9779137750992);
      // eslint-disable-next-line no-undef
      let float_6_12 = new BMap.Point(117.80195711412149, 38.987844410621338);
      // eslint-disable-next-line no-undef
      let float_6_13 = new BMap.Point(117.79772644845633, 38.992123492550508);
      // eslint-disable-next-line no-undef
      let float_6_14 = new BMap.Point(117.7971991753089, 38.994114976252017);

      // eslint-disable-next-line no-undef
      let beach_7_1 = new BMap.Point(117.808126863064, 38.995339566936397);
      // eslint-disable-next-line no-undef
      let beach_7_2 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let beach_7_3 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let beach_7_4 = new BMap.Point(117.799494969824, 39.028229590037167);
      // eslint-disable-next-line no-undef
      let beach_7_5 = new BMap.Point(117.808126863064, 38.995339566936397);

      // eslint-disable-next-line no-undef
      let float_7_1 = new BMap.Point(117.78865142443694, 39.02637168831018);
      // eslint-disable-next-line no-undef
      let float_7_2 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let float_7_3 = new BMap.Point(117.80118055676391, 38.994551773206598);
      // eslint-disable-next-line no-undef
      let float_7_4 = new BMap.Point(117.7971991753089, 38.994114976252017);
      // eslint-disable-next-line no-undef
      let float_7_5 = new BMap.Point(117.78865142443694, 39.02637168831018);

      // eslint-disable-next-line no-undef
      let beach_8_1 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let beach_8_2 = new BMap.Point(117.78575570283162, 39.0530681478733);
      // eslint-disable-next-line no-undef
      let beach_8_3 = new BMap.Point(117.77466875334567, 39.05724665067165);
      // eslint-disable-next-line no-undef
      let beach_8_4 = new BMap.Point(117.77679078302437, 39.060773693539498);
      // eslint-disable-next-line no-undef
      let beach_8_5 = new BMap.Point(117.792380241949, 39.055170535969718);
      // eslint-disable-next-line no-undef
      let beach_8_6 = new BMap.Point(117.799494969824, 39.028229590037167);
      // eslint-disable-next-line no-undef
      let beach_8_7 = new BMap.Point(117.7926448231615, 39.02704338722681);

      // eslint-disable-next-line no-undef
      let float_8_1 = new BMap.Point(117.7926448231615, 39.02704338722681);
      // eslint-disable-next-line no-undef
      let float_8_2 = new BMap.Point(117.78865142443694, 39.02637168831018);
      // eslint-disable-next-line no-undef
      let float_8_3 = new BMap.Point(117.78218759814257, 39.05062672712913);
      // eslint-disable-next-line no-undef
      let float_8_4 = new BMap.Point(117.77281891716619, 39.05417636480129);
      // eslint-disable-next-line no-undef
      let float_8_5 = new BMap.Point(117.77466875334567, 39.05724665067165);
      // eslint-disable-next-line no-undef
      let float_8_6 = new BMap.Point(117.78575570283162, 39.0530681478733);
      // eslint-disable-next-line no-undef
      let float_8_7 = new BMap.Point(117.7926448231615, 39.02704338722681);

      // eslint-disable-next-line no-undef
      let beach_5 = new BMap.Polygon(
        [beach_5_1, beach_5_2, beach_5_3, beach_5_4, beach_5_5],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_5);

      // eslint-disable-next-line no-undef
      let float_5 = new BMap.Polygon(
        [float_5_1, float_5_2, float_5_3, float_5_4, float_5_5, float_5_6],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_5);

      // eslint-disable-next-line no-undef
      let beach_6 = new BMap.Polygon(
        [
          beach_6_1,
          beach_6_2,
          beach_6_3,
          beach_6_4,
          beach_6_5,
          beach_6_6,
          beach_6_7,
          beach_6_8,
          beach_6_9,
          beach_6_10,
          beach_6_11,
          beach_6_12,
          beach_6_13,
          beach_6_14,
          beach_6_15,
        ],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_6);

      // eslint-disable-next-line no-undef
      let float_6 = new BMap.Polygon(
        [
          float_6_1,
          float_6_2,
          float_6_3,
          float_6_4,
          float_6_5,
          float_6_6,
          float_6_7,
          float_6_8,
          float_6_9,
          float_6_10,
          float_6_11,
          float_6_12,
          float_6_13,
          float_6_14,
        ],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_6);

      // eslint-disable-next-line no-undef
      let beach_7 = new BMap.Polygon(
        [beach_7_1, beach_7_2, beach_7_3, beach_7_4, beach_7_5],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_7);

      // eslint-disable-next-line no-undef
      let float_7 = new BMap.Polygon(
        [float_7_1, float_7_2, float_7_3, float_7_4, float_7_5],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_7);

      // eslint-disable-next-line no-undef
      let beach_8 = new BMap.Polygon(
        [
          beach_8_1,
          beach_8_2,
          beach_8_3,
          beach_8_4,
          beach_8_5,
          beach_8_6,
          beach_8_7,
        ],
        {
          strokeColor: "#4ec21b",
          fillColor: "#8ffc01",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(beach_8);

      // eslint-disable-next-line no-undef
      let float_8 = new BMap.Polygon(
        [
          float_8_1,
          float_8_2,
          float_8_3,
          float_8_4,
          float_8_5,
          float_8_6,
          float_8_7,
        ],
        {
          strokeColor: "#15276F",
          fillColor: "#22A7F2",
          fillOpacity: 0.25,
          strokeWeight: "5px",
        }
      );
      this.map.addOverlay(float_8);

      // eslint-disable-next-line no-undef
      let label_1 = new BMap.Label("1", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.800307, 39.071021),
      });
      label_1.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_1);

      // eslint-disable-next-line no-undef
      let label_2 = new BMap.Label("2", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.820729, 39.04816),
      });
      label_2.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_2);

      // eslint-disable-next-line no-undef
      let label_3 = new BMap.Label("3", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.823341, 39.029552),
      });
      label_3.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_3);

      // eslint-disable-next-line no-undef
      let label_4 = new BMap.Label("4", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.828377, 39.013594),
      });
      label_4.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_4);

      // eslint-disable-next-line no-undef
      let label_5 = new BMap.Label("5", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.838926, 38.990078),
      });
      label_5.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_5);

      // eslint-disable-next-line no-undef
      let label_6 = new BMap.Label("6", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.810368, 38.989405),
      });
      label_6.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_6);

      // eslint-disable-next-line no-undef
      let label_7 = new BMap.Label("7", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.798583, 39.012958),
      });
      label_7.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_7);

      // eslint-disable-next-line no-undef
      let label_8 = new BMap.Label("8", {
        // eslint-disable-next-line no-undef
        position: new BMap.Point(117.790965, 39.04065),
      });
      label_8.setStyle({
        color: "black",
        border: "0",
        background: "transparent",
        fontSize: "16px",
        fontWeight: "600",
        fontFamily: "微软雅黑",
      });
      this.map.addOverlay(label_8);
    },
    //修改选择的日期
    onChange(value, dateString) {
      this.createValue = value;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
    },
    //如果选择的类型变化
    typeChange() {
      this.pagination.current = 1;
    },
    //如果选择的地区发生变化
    regionChange() {
      this.pagination.current = 1;
    },
    //查询图片的接口函数
    async getPicturedata() {
      this.loading = true;
      let url = "http://39.100.158.75:8080/photo/findPhotoListByPage";
      this.$axios({
        url,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          startTime: this.startTime,
          endTime: this.endTime,
          regionId: this.region,
          type: this.type,
          sortType: "1",
          isRole: 0,
        },
      }).then((response) => {
        if (response.data.data == null) {
          message.info(response.data.message);
          this.data = [];
          this.pagination.total = 0;
          this.loading = false;
        } else {
          this.data = response.data.data.list;
          this.pagination.total = response.data.data.totalPageCount;
          if (this.pagination.current === this.pagination.total) {
            this.pointList = [];
          }
          this.displayAllPoints(this.data);
          this.loading = false;
        }
        this.showWhich = true;
      });
    },
    //查询视频的接口函数
    getVideoData() {
      this.loadingVideo = true;
      let url = "http://39.100.158.75:8080/photo/findVideoListByPage";
      this.$axios
        .request({
          url,
          method: "GET",
          headers: {
            token: this.$storage.get("userinfo").data.token,
          },
          params: {
            page: this.paginationVideo.current,
            limit: this.paginationVideo.pageSize,
            startTime: this.startTime,
            endTime: this.endTime,
            isRole: 0,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.data == null) {
            this.videoData = [];
            this.paginationVideo.total = 0;
          } else {
            this.videoData = response.data.data.list;
            this.paginationVideo.total = response.data.data.totalPageCount;
            if (this.pagination.current === this.pagination.total) {
              this.polylineList = [];
            }
            this.displayAllPolylines(this.videoData);
          }
          this.loadingVideo = false;
          this.showWhich = false;
        });
    },
    //选择展示图片表格
    showPicTable() {
      this.showWhich = true;
    },
    //选择展示视频表格
    showVideoTable() {
      this.showWhich = false;
    },
    //鼠标点击图片列表中的某一行
    rowClick(record, index) {
      return {
        style: {
          "background-color":
            this.selectedRow && this.selectedRow === record.id
              ? "RGB(0,240,0,50%)"
              : "#FFF", //行背景颜色的改变
        },
        onClick: () => {
          this.imageIndex = index;
          this.map.setZoom(17.4);
          //先将上一个选择的点的样式进行修改
          if (this.pointList.length > this.lastIndex) {
            this.pointList[this.lastIndex].setStyle({
              backgroundColor: "red",
              border: "1px solid red",
            });
          }
          this.selectedRow = record.id;
          // eslint-disable-next-line no-undef
          this.markerPoint = new BMap.Point(
            record.separateGps[0],
            record.separateGps[1]
          );
          //设置选中点的样式
          this.pointList[index].setStyle({
            backgroundColor: "blue",
            border: "1px solid blue",
          });
          this.map.setCenter(this.markerPoint); //设置中心坐标
          this.lastIndex = index; //设置上一个选择的点的样式
        },
      };
    },
    //点击视频列表的每一行
    rowClickVideo(record, index) {
      return {
        style: {
          "background-color":
            this.selectedRow && this.selectedRow == record.id
              ? "RGB(0,240,0,50%)"
              : "#FFF", //行背景颜色的改变
        },
        onClick: () => {
          this.map.setZoom(17.4);
          //先将上一个选择的点的样式进行修改
          if (this.polylineList.length > this.lastIndexVideo) {
            this.polylineList[this.lastIndexVideo].setStrokeColor("blue");
          }
          this.selectedRow = record.id;
          // eslint-disable-next-line no-undef
          if (record.checkPoint.length === 0) {
            alert("该视频没有拍摄轨迹！");
          } else {
            // eslint-disable-next-line no-undef
            this.markerPoint = new BMap.Point(
              record.checkPoint[0].separateGps[0],
              record.checkPoint[0].separateGps[1]
            );
            //设置选中折线的样式
            this.polylineList[index].setStrokeColor("red");
            this.map.setCenter(this.markerPoint); //设置中心坐标
            this.lastIndexVideo = index; //设置上一个选择的点的样式
          }
        },
      };
    },
    //图片列表换页
    changePicTableData(event) {
      this.pagination.current = event.current;
      this.getPicturedata();
    },
    //视频列表换页
    changeVideoTableData(event) {
      this.paginationVideo.current = event.current;
      this.getVideoData();
    },
    //渲染所有点
    async displayAllPoints(imageList) {
      //首先先移除点
      for (let i = 0; i < this.pointList.length; i++) {
        this.map.removeOverlay(this.pointList[i]);
      }
      //保存渲染点的数组清空
      this.pointList = [];
      //如果有图片
      if (imageList.length !== 0) {
        for (let i = 0; i < imageList.length; i++) {
          // eslint-disable-next-line no-undef
          let point = new BMap.Point(
            imageList[i].separateGps[0],
            imageList[i].separateGps[1]
          );
          // eslint-disable-next-line no-undef
          let label = new BMap.Label(i + 1, {
            position: point,
            // eslint-disable-next-line no-undef
            offset: new BMap.Size(0, 0),
          });
          label.setStyle({
            width: "15px",
            height: "20px",
            background: "red",
            color: "#FFF",
            textAlign: "center",
            fontWeight: "600",
          });
          this.map.addOverlay(label);
          this.pointList.push(label);
          let dbclickFun = () => {
            this.$refs.pic_ref.style.display = "block";
            this.imageIndex = i;
            this.showPhoto(imageList[i]);
          };
          label.addEventListener("dblclick", dbclickFun);
        }
      }
    },
    //渲染所有的折线
    async displayAllPolylines(pointList) {
      //首先清空之前渲染的折线
      for (let i = 0; i < this.polylineList.length; i++) {
        this.map.removeOverlay(this.polylineList[i]);
      }
      //将保存折线的数组清空
      this.polylineList = [];
      //判断每一个视频的对应折线
      for (let i = 0; i < pointList.length; i++) {
        //如果该视频有折线
        if (pointList[i].checkPoint.length !== 0) {
          let points = [];
          for (let j = 0; j < pointList[i].checkPoint.length; j++) {
            // eslint-disable-next-line no-undef
            let point = new BMap.Point(
              pointList[i].checkPoint[j].separateGps[0],
              pointList[i].checkPoint[j].separateGps[1]
            );
            points.push(point);
          }
          // eslint-disable-next-line no-undef
          let icon = new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
            scale: 0.6, //图标缩放大小
            strokeColor: "#fff", //设置矢量图标的线填充颜色
            strokeWeight: 1.5, //设置线宽
          });
          // eslint-disable-next-line no-undef
          let icons = new BMap.IconSequence(icon, "200%", "80%", false); //设置为true，可以对轨迹进行编辑
          // eslint-disable-next-line no-undef
          let polyline = new BMap.Polyline(points, {
            strokeColor: "blue",
            strokeWeight: 5,
            strokeOpacity: 0.5,
            icons: [icons],
          });
          this.map.addOverlay(polyline);
          this.polylineList.push(polyline);
          let dbclickVideo = () => {
            this.videoVisible = true;
            this.videoUrl = pointList[i].videoUrlModel.videoUrl;
          };
          polyline.addEventListener("dblclick", dbclickVideo);
        }
      }
    },
    //看视频
    showVideo(text) {
      this.videoVisible = true;
      this.videoUrl = text.videoUrl;
    },
    //初始化图片图层
    initImage(photoUrl) {
      //容器对象声明
      this.gMap = new AILabel.Map("image-box", {
        zoom: 1080, //初始缩放级别
        cx: 0, //初始中心点坐标x
        cy: 0, //初始中心点坐标y
        zoomMax: 1080, //缩放的最大级别
        zoomMin: 400, //缩放的最小级别
      });
      //图片层实例
      this.gImageLayer = new AILabel.Layer.Image(
        "img1", //实例图层的唯一标志id
        photoUrl, //图像的src
        { w: 1080, h: 607.5 }, //图像的原始宽高
        { zIndex: 1 } //config，这里的zIndex决定显示的层级
      );
      //图片层实例的添加
      this.gMap.addLayer(this.gImageLayer);
      message.success("图片加载成功", 0.4);
    },
    // 这个是点击后展示图片与图层
    async showPhoto(record) {
      this.pythonImgInfo = record;
      await this.getNearPhotos(this.pythonImgInfo);
      this.$refs.pic_ref.style.display = "block";
      // 这个是真正的请求获取图片与图层的函数
      this.initImage(this.pythonImgInfo.photoUrl);
    },
    //点击完图层之后请求离双击的图片最近的一系列图片信息，并初始化按钮的显示等
    async getNearPhotos(record) {
      let result = await this.$axios({
        url: "http://39.100.158.75:8080/photo/findNearPhoto",
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          gps: record.gps,
          time: record.photoTime,
        },
      });
      result = result.data.data;
      //判定按钮的显示等
      (this.isButtonBackShow = false), (this.isButtonGoShow = false);
      if (result.before.length > 0) {
        this.isButtonBackShow = true;
      }
      if (result.later.length > 0) {
        this.isButtonGoShow = true;
      }
      //整合数据
      this.totalArray = [];
      this.totalArray.push(...result.before);
      (this.nowPositionInArray = this.totalArray.length),
        (this.nowIndex = this.totalArray.length);
      this.totalArray.push(this.pythonImgInfo);
      this.totalArray.push(...result.later);
    },
    //切换图层展示
    async changeLevel() {
      if (this.isPythonUrl === 0) {
        this.isPythonUrl = 1;
        let pythonImg = await this.$axios({
          url: "http://39.100.158.75:8080/photo/findBlackPhotoByPhotoId",
          method: "GET",
          headers: {
            token: this.$storage.get("userinfo").data.token,
          },
          params: {
            photoId: this.pythonImgInfo.id,
          },
        });
        pythonImg = pythonImg.data.data;
        this.pythonImgInfo.pythonImg = pythonImg;
        this.initImage(this.pythonImgInfo.pythonImg.photoUrl);
      } else {
        this.isPythonUrl = 0;
        this.initImage(this.pythonImgInfo.photoUrl);
      }
    },
    //图片180°翻转
    changeImgPosition() {
      if (this.isImgPositionRight === true) {
        this.isImgPositionRight = false;
        this.$refs.imgRef.style.transform =
          "rotate(180deg) translate(50%, 50%)";
      } else {
        this.isImgPositionRight = true;
        this.$refs.imgRef.style.transform = "translate(-50%, -50%)";
      }
      this.isPicChangeLoading = false;
    },
    //前进
    async nextFun() {
      this.$refs.imgRef.style.transform = "translate(-50%, -50%)";
      //先渲染图片
      this.pythonImgInfo = this.totalArray[++this.nowPositionInArray];
      this.initImage(this.pythonImgInfo.photoUrl);
      //再渲染箭头的显示
      this.isButtonBackShow = true;
      if (this.nowPositionInArray === this.totalArray.length - 1) {
        this.isButtonGoShow = false;
      }
      if (this.nowPositionInArray === this.nowIndex) {
        message.success("地图上选择的照片！", 0.5);
      }
    },
    //后退
    async backFun() {
      this.$refs.imgRef.style.transform = "translate(-50%, -50%)";
      //先渲染图片
      this.pythonImgInfo = this.totalArray[--this.nowPositionInArray];
      this.initImage(this.pythonImgInfo.photoUrl);
      //再渲染箭头的显示
      this.isButtonGoShow = true;
      if (this.nowPositionInArray === 0) {
        this.isButtonBackShow = false;
      }
      if (this.nowPositionInArray === this.nowIndex) {
        message.success("地图上选择的照片！", 0.5);
      }
    },
    //关闭图层
    deletePic() {
      this.$refs.pic_ref.style.display = "none";
      this.isButtonGoShow = false;
      this.isButtonBackShow = false;
    },
    //打开上传至魅力东疆的模态框
    openUploadCharm() {
      this.visibleCharm = true;
    },
    //上传至魅力东疆
    uploadCharm() {
      let url = "http://39.100.158.75:8080/showPhoto/insertOneShowPhoto";
      this.$axios({
        url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        data: JSON.stringify({
          operatorId: this.$storage.get("userinfo").data.userId,
          photoId: this.pythonImgInfo.id,
          message: this.text,
          regionId: this.pythonImgInfo.regionId,
          type: 0,
        }),
      }).then((res) => {
        message.info(res.data.message);
        this.visibleCharm = false;
        this.text = "";
      });
    },
    //设置键盘左右键响应事件
    keyHandle(event) {
      //只有在模态框开启的情况下才能使用
      if (this.$refs.pic_ref.style.display === "block") {
        //右键
        if (
          event &&
          event.keyCode === 39 &&
          this.visibleCharm === false &&
          this.visibleTask === false
        ) {
          if (this.imageIndex + 1 < this.data.length) {
            this.showPhoto(this.data[++this.imageIndex]);
          } else {
            message.error("已到最后一页，请换页");
          }
        }
        //左键
        if (
          event &&
          event.keyCode === 37 &&
          this.visibleCharm === false &&
          this.visibleTask === false
        ) {
          if (this.imageIndex - 1 >= 0) {
            this.showPhoto(this.data[--this.imageIndex]);
          } else {
            message.error("这是第一张图片");
          }
          console.log(this.imageIndex);
        }
      }
    },
    //定点任务派送
    async createTask() {
      this.taskMessage = "";
      this.taskTimeValues = [getAnyDate(0), getAnyDate(3)];
      this.cleaner = null;
      let result = await this.$axios({
        url: "http://39.100.158.75:8080/manager/findCleaner",
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          role: 2,
        },
      });
      this.cleanerList = result.data.data;
      this.visibleTask = true;
    },
    uploadTask() {
      this.$axios({
        url: "http://39.100.158.75:8080/pointMission/insertOnePointMission",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        data: JSON.stringify({
          recipientId: this.cleaner,
          startTime: this.taskTime.startTime,
          endTime: this.taskTime.endTime,
          message: this.taskMessage,
          success: 0,
          name: "清理此处垃圾",
          photoId: this.pythonImgInfo.id,
        }),
      }).then((res) => {
        console.log(res);
        message.success("任务分配成功！");
        this.visibleTask = false;
      });
    },
  },
  mounted() {
    //地图初始化
    this.init();
    //获取初始数据
    this.getVideoData();
    this.getPicturedata();
  },
  created() {
    document.addEventListener("keyup", this.keyHandle, true);
    this.role = this.$storage.get("userinfo").data.role;
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyHandle, true);
  },
});
</script>

<style lang="scss" scoped>
.global-box {
  display: flex;
  position: relative;
  height: 100%;
  background: transparent;

  .bird-nav {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 20;
  }

  .table-box {
    position: absolute;
    top: 45px;
    left: 5px;
    width: 30%;
    z-index: 20;

    .transparent-table {
      width: 100%;
      border: 0;
    }
  }

  .map-box {
    flex: 1;
    height: 100%;
    position: relative;
    z-index: 19;

    #bird-map {
      height: 100%;
    }
  }
}
.pic-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
  .delete-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(530px, -325.75px);
    .a-button {
      width: 35px;
      height: 35px;
      font-size: 20px;
    }
  }
  .change-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-540px, -340.75px);
  }
  .is-show-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-420px, -340.75px);
  }
  .charm-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-328px, -340.75px);
  }
  .task-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-195px, -340.75px);
  }
  .tag-box {
    height: 32px;
    width: 240px;
    color: #fff;
    font-size: 14px;
    background: #1890ff;
    padding: 5px 15px;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-82px, -340.75px);
  }
  .tag-gps-box {
    height: 32px;
    width: 350px;
    color: #fff;
    font-size: 14px;
    background: #1890ff;
    padding: 5px 15px;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(161px, -340.75px);
  }
  .go-button-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(550px, -35px);
  }
  .go-button-box:hover {
    cursor: pointer;
  }
  .back-button-box {
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-620px, -35px);
  }
  .back-button-box:hover {
    cursor: pointer;
  }
  #image-box {
    position: absolute;
    width: 1080px;
    height: 607.5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ant-btn-primary {
  background-color: rgba(24, 144, 255, 0.6) !important;
  border-color: rgba(24, 144, 255, 0.6) !important;
}

:deep().ant-table-header {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 0 !important;
}

:deep().ant-table {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 0 !important;
}

:deep().ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 0 !important;
  padding: 10px;
}

:deep().ant-table-placeholder {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 0 !important;
}

:deep().ant-table-tbody > tr > td {
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 0;
  padding: 3px;
}

:deep().ant-table-tbody > tr > td:first-child {
  border-left: 0 !important;
  padding: 3px;
}

:deep().ant-table-tbody > tr > td:last-child {
  border-right: 0 !important;
  padding: 7px;
}
.task-text-area {
  width: 85%;
}
</style>
